<template>
  <div>
    <div class="aboutus_img">
      <div class="contactus">
        <h2 class="text__cont">{{ $t("news.title") }}</h2>
      </div>
    </div>
    <div class="main__container">
      <div class="aboutus__cont">
        <div class="aboutus__info">
          <div class="about__title">
            <h2>{{ $t("news.block1.title") }}</h2>
          </div>
          <div class="text__title">
            <p>
              {{ $t("news.block1.content") }}
            </p>
          </div>
        </div>
        <div class="aboutus__title__img news1"></div>
      </div>
      <div class="aboutus__cont_rev">
        <div class="aboutus__info">
          <div class="about__title">
            <h2>{{ $t("news.block2.title") }}</h2>
          </div>
          <div class="text__title">
            <p>
              {{ $t("news.block2.content") }}
            </p>
          </div>
        </div>
        <div class="aboutus__title__img news2"></div>
      </div>
      <div class="aboutus__cont">
        <div class="aboutus__info">
          <div class="about__title">
            <h2>{{ $t("news.block3.title") }}</h2>
          </div>
          <div class="text__title">
            <p>
              {{ $t("news.block3.content") }}
            </p>
          </div>
        </div>
        <div class="aboutus__title__img news3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.classList.remove("page");
  },

  destroyed() {
    document.body.classList.add("page");
  },

  metaInfo() {
    return {
      title: this.$t("seo.news.title"),
      meta: [
        {
          name: "description",
          content: this.$t("seo.news.description")
        },
        {
          name: "keywords",
          content: this.$t("seo.news.keywords")
        }
      ]
    };
  }
};
</script>
